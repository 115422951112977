import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import { NextSeo } from 'next-seo';

import Layout from '../layout';
import { render } from '../components/render';
import { getPage } from '../redux/page/page.action';
import { pageGetData } from '../redux/page/page.selector';
import { getGlobal } from '../redux/global/global.action';
import { globalGetData, globalInit } from '../redux/global/global.selector';
import { checkUASelectors, tryParseJSON } from '../utils/common';

// import Offices from '../components/Offices';

const IndexPage = props => {
    const { Global, Seo, Contents, isMobileUA } = props;
    const firstCompType = Contents ? Contents[0].type : '';

    const structuredDataValid = tryParseJSON(Seo?.pageSeoStructuredData);
    return (
        <Layout {...Global} firstCompType={firstCompType}>
            <NextSeo title={Seo.pageTitle} description={Seo.pageDescription} canonical={Seo?.pageUrl} />
            <Head>
                <meta name='google-site-verification' content='Nd8hWdAqqvXuwbAn3xvEO9UOvXsnP-oauHB5gk2mro8' />
                <link rel='shortcut icon' href={Global.favicon?.url} />
                <meta name='title' content={Seo?.pageTitle} />
                <meta name='keywords' content={Seo?.pageKeywords} />
                <meta name='thumbnail' content={Seo?.pageOgImage} />
                <meta property='og:url' content={Seo?.pageUrl} />
                <meta property='og:title' content={Seo?.pageOgTitle} />
                <meta property='og:description' content={Seo?.pageOgDescription} />
                <meta property='og:image' content={Seo?.pageOgImage} />
                {structuredDataValid && (
                    <script
                        type='application/ld+json'
                        dangerouslySetInnerHTML={{
                            __html: Seo?.pageSeoStructuredData,
                        }}
                    ></script>
                )}
            </Head>
            {/* <Offices {...Contents[14]} />
            <Banner {...Contents[0]} />
            <HomeTreasers {...Contents[1]} />
            <LatestNews {...Contents[2]} />
            <LifeAtPermira {...Contents[3]} /> */}
            {Contents && Contents.map((content, i) => render(content, i, Contents, false, isMobileUA))}
        </Layout>
    );
};

IndexPage.getInitialProps = async ctx => {
    const { query, req } = ctx;
    const { lang, preview, pageId } = query;

    const { dispatch, getState } = ctx.store;
    await dispatch(getPage('', lang, preview, pageId));
    const PageData = pageGetData(getState());

    // global header, footer
    const isGlobalInit = globalInit(getState());

    if (!isGlobalInit || !globalGetData(getState()).data) {
        await dispatch(getGlobal(lang));
    }

    const GlobalData = globalGetData(getState());
    const Global = GlobalData.data;

    // const Global = reformatGlobal(PageData.Global, Labels, lang);
    const Seo = PageData.SEO;
    const Contents = PageData.contents;

    const isMobileUA = checkUASelectors(req);

    return {
        Global,
        Seo,
        Contents,
        isMobileUA,
    };
};

IndexPage.propTypes = {
    Global: PropTypes.object,
    Seo: PropTypes.object,
    Contents: PropTypes.array,
    isMobileUA: PropTypes.bool,
};

export default IndexPage;
